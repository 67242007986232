let tabs = document.querySelector('#tabs');
if (tabs) {
    tabs.addEventListener('click', function(event) {
        let target = event.target.closest('li');
        if (!target) return false;
        let index;
        for (let i = 0; i < tabs.children.length; i++) {
            if (tabs.children[i] == target) {
                index = i;
                break;
            }
        }
        if (index === undefined) return false;
        let tab = document.querySelectorAll('.product-item_description');
        for (let i = 0; i < tab.length; i++) {
            if (i === index) {
                tab[i].removeAttribute('style');
                tabs.children[i].classList.add('active');
            } else {
                tab[i].style.display = 'none';
                tabs.children[i].classList.remove('active');
            }
        }
    });
}
